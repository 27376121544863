import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import {
  JoinContainer,
  JoinInput,
  JoinButton,
} from './JoinMeetingFormComponents'
import LottieShape from 'components/animations/LottieShape'
import LoadingAnimation from 'components/animations/LoadingAnimation'

const JoinMeetingForm = ({
  onClickJoinMeeting,
  setIsJoinLinkTextValid,
  checkIfMeetingExistsLoading,
}) => {
  const { t } = useTranslation('joinMeetingForm')
  const [joinLinkText, setJoinLinkText] = useState('')
  const [joinLink, setJoinLink] = useState(false)
  const joinButtonRef = useRef(null)
  const OFF = 'off'

  const onChangeJoinLinkText = (e) => {
    setIsJoinLinkTextValid(true)
    setJoinLinkText(e.target.value)
  }

  const handleKeypress = (e) => {
    if (e.code === 'Enter') {
      joinButtonRef.current?.click()
    }
  }

  return (
    <JoinContainer>
      <JoinInput
        type="text"
        placeholder={t('enterCode')}
        onClick={() => setJoinLink(true)}
        onChange={(e) => onChangeJoinLinkText(e)}
        onKeyPress={handleKeypress}
        autoComplete={OFF}
        autoCompleteType={OFF}
        autoCorrect={OFF}
        autoCapitalize={OFF}
        spellCheck={false}
      />
      {joinLink ? (
        <JoinButton
          disabled={joinLinkText.length === 0}
          onClick={() => onClickJoinMeeting(joinLinkText.trim())}
          ref={joinButtonRef}
        >
          {checkIfMeetingExistsLoading ? (
            <LottieShape animationData={LoadingAnimation} size={48} />
          ) : (
            t('button')
          )}
        </JoinButton>
      ) : null}
    </JoinContainer>
  )
}

JoinMeetingForm.propTypes = {
  onClickJoinMeeting: PropTypes.func.isRequired,
  setIsJoinLinkTextValid: PropTypes.func.isRequired,
  checkIfMeetingExistsLoading: PropTypes.bool.isRequired,
}

export default JoinMeetingForm
