import { React, useEffect, useContext, useCallback } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { FiX, FiCopy } from 'react-icons/fi'
import OutsideClickHandler from 'react-outside-click-handler'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'
import copy from 'copy-to-clipboard'
import { useTranslation } from 'react-i18next'

import { CREATE_MEETING } from 'services/graphql'
import UserContext from 'context/user/UserContext'
import LottieShape from 'components/animations/LottieShape'
import LoadingAnimation from 'components/animations/LoadingAnimation'

const ShareLinkModal = ({ setIsOpen, isOpen }) => {
  const { t } = useTranslation('shareLink')
  const { user } = useContext(UserContext)
  const ERROR_MESSAGE = t('error')

  const copyText = () => {
    copy(inviteLink)
    notifyCopiedLink()
  }

  const notifyError = useCallback(() => toast.error(t('error')), [t])

  const notifyCopiedLink = useCallback(() => toast.dark(t('copiedLink')), [t])

  const notifyUnauthUser = useCallback(() => toast.dark(t('signInRequired')), [
    t,
  ])

  const [
    createMeeting,
    {
      data: createMeetingData,
      loading: createMeetingLoading,
      error: createMeetingError,
    },
  ] = useMutation(CREATE_MEETING)

  useEffect(() => {
    if (createMeetingError) {
      notifyError()
    }
  }, [createMeetingError, notifyError])

  useEffect(() => {
    const onGetMeetingLinkToShare = async () => {
      if (user && user.uid) {
        await createMeeting()
      } else {
        notifyUnauthUser()
      }
    }

    if (isOpen) {
      onGetMeetingLinkToShare()
    }
  }, [isOpen, createMeeting, user, notifyUnauthUser])

  const inviteLink =
    createMeetingData &&
    createMeetingData.createMeeting &&
    createMeetingData.createMeeting.inviteLink
      ? createMeetingData.createMeeting.inviteLink
      : ERROR_MESSAGE

  let displayedInviteLink = inviteLink

  // If valid invite link, strip https / protocol (see Google Meet's implementation)
  if (displayedInviteLink && displayedInviteLink !== ERROR_MESSAGE) {
    displayedInviteLink = displayedInviteLink.replace(/(^\w+:|^)\/\//, '')
  }

  return (
    <ShareLinkModalContainer>
      <OutsideClickHandler
        onOutsideClick={() => {
          setIsOpen(false)
        }}
      >
        <ShareLinkModalContent>
          <p>
            {t('heading')}
            <FiX
              size="1.6em"
              style={{ cursor: 'pointer' }}
              onClick={() => setIsOpen(false)}
            />
          </p>
          <BoxContainer>
            <p>{t('message')}</p>
            {createMeetingLoading ? (
              <LottieShape animationData={LoadingAnimation} size={80} />
            ) : (
              <ShareLinkBox>
                <ShareLinkText id="link" value={displayedInviteLink} />
                <FiCopyButton size="3.3em" onClick={() => copyText()} />
              </ShareLinkBox>
            )}
          </BoxContainer>
        </ShareLinkModalContent>
      </OutsideClickHandler>
    </ShareLinkModalContainer>
  )
}

ShareLinkModal.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
}

export default ShareLinkModal

const ShareLinkModalContainer = styled.div`
  display: block;
  position: absolute;
  font-family: Roboto, Arial, sans-serif;
  z-index: 1;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
`

const ShareLinkModalContent = styled.div`
  background-color: #fefefe;
  padding: 1em;
  width: 24em;
  border-radius: 1em;
  height: 14em;
  animation-name: expand;
  animation-duration: 0.1s;
  animation-timing-function: ease-out;
  transition: transform 0.8s cubic-bezier(0, 0, 0.2, 1);

  @keyframes expand {
    0% {
      transform: scale(0.3);
    }

    100% {
      transform: scale(1);
    }
  }

  p:nth-child(2) {
    color: #5f6368;
    font-size: 0.9em;
    margin-bottom: 0.5em;
  }

  p {
    display: flex;
    justify-content: space-between;
  }
`

const ShareLinkBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f1f3f4;
  margin: 0em 0.5em;
  text-align: center;
  border: none;
  border-radius: 4px;
  outline: none;
  height: 3em;

  p {
    display: flex;
    justify-content: space-between;
    color: #5f6368;
    font-size: 0.9em;
    margin-bottom: 0.5em;
  }
`

const ShareLinkText = styled.input`
  display: flex;
  align-items: center;
  background: #f1f3f4;
  color: #202124;
  padding-right: 2em;
  padding-left: 1em;
  border: none;
  border-radius: 4px;
  outline: none;
  height: 3em;
  width: 24em;
  font-size: 0.8em;
`

const BoxContainer = styled.div`
  p {
    color: #5f6368;
    font-size: 0.9em;
    margin-bottom: 0.5em;

    display: flex;
    justify-content: space-between;
  }
`

const FiCopyButton = styled(FiCopy)`
  cursor: pointer;
  color: #5f6368;
  padding-right: 1em;
  padding-left: 1em;
  &:visited {
    background: green;
  }
  &:hover {
    background: #e6e1e1;
    border-radius: 30px;
  }
  &:active {
    background: #969696;
    animation-name: expand;
    animation-duration: 0.1s;
    animation-timing-function: ease-out;
    @keyframes expand {
      0% {
        transform: scale(0.9);
      }

      100% {
        transform: scale(1);
      }
    }
  }
`
