import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const VideoModal = styled.div`
  display: block;
  position: fixed;
  font-family: Roboto, Arial, sans-serif;
  z-index: 1;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
`

const VideoBody = styled.div`
  max-width: 940px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const YouTubeModal = ({ youtubeVideoId, title, onClick, autoplay }) => {
  return (
    <VideoModal onClick={onClick}>
      <VideoBody>
        <iframe
          id="ytplayer"
          type="text/html"
          width="900"
          height="500"
          src={`https://www.youtube.com/embed/${youtubeVideoId}?autoplay=${
            autoplay ? '1' : '0'
          }&origin=${process.env.GATSBY_SITE_BASE_URL}`}
          frameborder="0"
          title={title}
        ></iframe>
      </VideoBody>
    </VideoModal>
  )
}

YouTubeModal.defaultProps = {
  title: null,
  onClick: null,
  autoplay: true,
}

YouTubeModal.propTypes = {
  youtubeVideoId: PropTypes.string.isRequired,
  title: PropTypes.string,
  onClick: PropTypes.func,
  autoplay: PropTypes.bool,
}

export default YouTubeModal
