import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StaticImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'
import { Element } from 'react-scroll'

import mq from 'styles/breakpoints'
import PricingPlans from 'components/PricingPlans'
import checkmark from 'images/png/checkmark.png'
import YouTubeModal from 'components/common/YouTubeModal'

const SectionContainerTop = styled.div`
  display: flex;
  min-height: 100%;

  ${mq['lg']} {
    flex-direction: column;
  }

  ${mq['md']} {
    min-height: 100%;
  }
`

const SectionContainerLeft = styled.div`
  width: 50%;

  ${mq['lg']} {
    width: 100%;
  }
`
const PeopleImage = styled.div`
  div {
    min-height: 100%;
    width: 100%;

    img {
      object-fit: contain;
    }
  }

  ${mq['lg']} {
    min-height: 100%;
    div {
      min-height: 100%;
    }
  }
`

const SectionContainerRight = styled.div`
  width: 50%;
  padding-left: 3%;

  ${mq['lg']} {
    width: 100%;
  }
`

const SectionText = styled.div`
  display: flex;
  margin-right: 2em;
  font-size: 1.5rem;
  height: 100%;
  flex-direction: column;

  h1 {
    margin-top: 0em;
    margin-bottom: 0em;
    font-size: 2.5em;
    font-weight: 200;
  }

  p {
    padding-top: 2em;
    width: 25em;
    color: #9ea3a0;
    font-family: Roboto;
    padding-right: 8em;
  }

  ${mq['xxl']} {
    font-size: 1.3rem;
  }

  ${mq['xl']} {
    padding-top: 1em;
    font-size: 1rem;
  }

  ${mq['lg']} {
    padding-top: 1em;
    margin-left: 0em;
    margin-right: 0em;
    text-align: center;
    width: 100%;

    p {
      width: 100%;
      padding-left: 12em;
      padding-right: 12em;
    }
  }

  ${mq['sm']} {
    p {
      padding-left: 2em;
      padding-right: 2em;
      padding-top: 2em;
    }
  }

  ${mq['xsm']} {
    h1 {
      font-size: 2em;
    }
  }

  a {
    cursor: pointer;
  }
`

const SectionContainerBottom = styled.div`
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  margin-top: 6em;

  ${mq['md']} {
    margin-top: 3em;
  }
`

const SectionHeader = styled.div`
  text-align: center;
  width: 100%;
  h1 {
    text-align: center;
    margin-top: 0em;
    margin-bottom: 0em;
    font-weight: 200;
  }

  p {
    color: grey;
    margin-top: 1em;
  }
`

const SectionImage = styled.div`
  text-align: center;
  width: 100%;
  padding-left: 20%;
  padding-right: 20%;

  ${mq['md']} {
    padding-left: 2em;
    padding-right: 2em;
  }
`

const BulletListWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  ${mq['md']} {
    flex-direction: column;
  }
`

const BulletLeft = styled.div`
  text-align: center;

  ul {
    margin-left: 0em;
    list-style-type: none;
    li:nth-of-type(1) {
      color: gray;
      font-size: 1.5em;
      font-weight: bold;
    }
  }
`

const BulletRight = styled(BulletLeft)`
  margin-left: 15em;

  ${mq['lg']} {
    margin-left: 3em;
  }

  ${mq['md']} {
    margin-left: 0em;
  }
`

const Checkmark = styled.img`
  display: inline;
  width: 2em;
`

const AboutSection = () => {
  const { t } = useTranslation('aboutSection')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModal = () => setIsModalOpen(!isModalOpen)

  return (
    <>
      {isModalOpen ? (
        <YouTubeModal
          onClick={toggleModal}
          youtubeVideoId={t('youtubeIdLearnMore')}
          title={t('youtubeTitleLearnMore')}
        />
      ) : null}
      <SectionContainerTop>
        <SectionContainerLeft>
          <PeopleImage>
            <StaticImage
              src="../../images/jpg/multiplePeople.jpg"
              alt={t('altImagePeople')}
              layout="constrained"
            />
          </PeopleImage>
        </SectionContainerLeft>
        <SectionContainerRight>
          <SectionText>
            <h1>{t('everyDevice')}</h1>
            <h1>{t('everyWalkOfLife')}</h1>
            <p>
              {t('paragraph1')} <br />
              <a
                onClick={() => toggleModal()}
                role="button"
                tabIndex={0}
                href="#/"
              >
                {t('learnMore')}
              </a>
            </p>
          </SectionText>
        </SectionContainerRight>
      </SectionContainerTop>
      <Element name="PricingPlans">
        <PricingPlans />
      </Element>
      <SectionContainerBottom>
        <SectionHeader>
          <h1>{t('heading2')}</h1>
          <p>{t('subheading2')}</p>
        </SectionHeader>
        <SectionImage>
          <StaticImage
            src="../../images/jpg/computer.jpg"
            alt={t('altImageComputer')}
            layout="constrained"
          />
        </SectionImage>
        <BulletListWrapper>
          <BulletLeft>
            <ul>
              <li>
                {t('leftFeatureListHeading')}
                <Checkmark src={checkmark} alt={t('altCheckmark')}></Checkmark>
              </li>
              <li>{t('microphoneAndCamera')}</li>
              <li>{t('screenSharing')}</li>
              <li>{t('chat')}</li>
              <li>{t('fileShare')}</li>
            </ul>
          </BulletLeft>
          <BulletRight>
            <ul>
              <li>
                {t('rightFeatureListHeading')}
                <Checkmark src={checkmark} alt={t('altCheckmark')}></Checkmark>
              </li>
              <li>{t('explore')}</li>
              <li>{t('customizeAvatar')}</li>
              <li>{t('viewboards')}</li>
              <li>{t('voiceLocation')}</li>
              <li>{t('hostPermissions')}</li>
              <li>{t('streaming')}</li>
            </ul>
          </BulletRight>
        </BulletListWrapper>
      </SectionContainerBottom>
    </>
  )
}

export default AboutSection
