import React from 'react'
import { useTranslation } from 'react-i18next'
import { navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'

import mq from 'styles/breakpoints'
import Button from 'components/common/Button'

const SectionContainer = styled.div`
  position: relative;
  min-height: 100vh;
`

const GirlImage = styled.div`
  position: relative;
  z-index: -1;
  display: flex;
  justify-content: center;

  div {
    height: 100vh;
    width: 100%;
  }

  ${mq['xxl']} {
    div {
      height: 100vh;
    }
  }

  ${mq['xl']} {
    div {
      height: 100vh;
      width: 100%;
    }
  }
`
const SectionHeader = styled.div`
  text-align: center;
  width: 100%;

  h1 {
    text-align: center;
    margin-top: 0em;
    font-weight: 200;
    margin-bottom: 0.75rem;
  }
`

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 6em;
  display: flex;
  text-align: center;
  width: 100%;
  justify-content: center;

  ${mq['xl']} {
    bottom: 18em;
  }

  ${mq['md']} {
    bottom: 12em;
  }

  ${mq['xsm']} {
    bottom: 10em;
  }
`

const CallToActionSection = () => {
  const { t } = useTranslation('callToActionSection')

  return (
    <SectionContainer>
      <SectionHeader>
        <h1>{t('heading1')}</h1>
      </SectionHeader>
      <GirlImage>
        <StaticImage
          src="../../images/jpg/happy-girl.jpg"
          alt={t('altImageGirl')}
          layout="fullWidth"
        />
      </GirlImage>
      <ButtonWrapper>
        <Button
          text={t('tryButton')}
          onClick={() => navigate(process.env.GATSBY_SITE_ACCOUNT_PATH)}
          width="12.6rem"
        />
      </ButtonWrapper>
    </SectionContainer>
  )
}

export default CallToActionSection
