import styled from '@emotion/styled'
import mq from 'styles/breakpoints'

export const SectionOne = styled.div`
  display: flex;
  height: 83vh;
  justify-content: space-around;
  align-items: center;

  ${mq['lg']} {
    flex-direction: column;
  }

  ${mq['xl']} {
    height: 100vh;
  }
`

export const SectionOneLeft = styled.div`
  padding-bottom: 6em;
  padding-left: 2em;

  ${mq['md']} {
    padding-bottom: 0;
  }
`

export const SectionOneText = styled.div`
  margin-left: 2em;
  margin-right: 2em;
  padding-top: 12em;
  font-size: 1.2em;

  h1 {
    margin-top: 0em;
    margin-bottom: 0em;
    font-weight: 200;
    font-size: 1.75em;
  }

  p {
    padding-top: 1em;
    color: #9ea3a0;
    font-family: Roboto;
    font-weight: 400;
    width: 25em;
  }

  ${mq['xl']} {
    p {
      width: 21em;
    }
  }

  ${mq['md']} {
    padding-top: 5em;
    h1 {
      font-size: 1.4em;
    }
  }

  ${mq['sm']} {
    h1 {
      font-size: 1.3rem;
    }

    p {
      width: 21em;
      font-size: 1rem;
      margin-right: 1em;
    }
  }

  ${mq['xsm']} {
    h1 {
      font-size: 1.2rem;
    }

    p {
      padding-top: 0.5em;
      width: 16em;
      font-size: 0.9rem;
    }
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 2.5em;
  margin-bottom: 2em;

  ${mq['xl']} {
    display: flex;
    flex-direction: column;
  }
`

export const SectionOneRight = styled.div`
  position: relative;
`

export const ScreenImage = styled.img`
  padding-top: 4em;
  padding-right: 10em;

  ${mq['xl']} {
    display: none;
  }

  @media (min-width: 1200px) {
    height: 25em;
    padding-right 13em;
  }

  @media (min-width: 1400px) {
    height: 35em;
    padding-right 13em;
  }

  @media (min-width: 1620px) {
    height: 39em;
    padding-right: 16em;
  }

  @media (min-width: 2000px) {
    height: 55em;
    padding-right: 20em;
  }
`
