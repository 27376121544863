import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import SEO from 'components/seo'
import Navbar from 'components/navbar'
import IndexLayout from 'components/IndexLayout'
import LandingSection from 'components/sections/LandingSection/LandingSection'
import AboutSection from 'components/sections/AboutSection'
import CallToActionSection from 'components/sections/CallToActionSection'

const IndexPage = () => {
  const { t } = useTranslation('home')
  const [isNavbarOpen, setNavbarOpen] = useState(false)

  return (
    <IndexLayout>
      <SEO
        title={t('title')}
        description={t('description')}
        keywords={t('keywords')}
      />
      <Navbar isNavbarOpen={isNavbarOpen} setNavbarOpen={setNavbarOpen} />
      <LandingSection />
      <AboutSection />
      <CallToActionSection />
    </IndexLayout>
  )
}

export default IndexPage
