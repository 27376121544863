import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import OutsideClickHandler from 'react-outside-click-handler'
import styled from '@emotion/styled'
import { FiLink2, FiPlus, FiSettings } from 'react-icons/fi'

import LottieShape from 'components/animations/LottieShape'
import LoadingAnimation from 'components/animations/LoadingAnimation'

const OptionsModal = styled.div`
  background: white;
  color: black;
  font-family: Roboto, Arial, sans-serif;
  width: 18em;
  padding-top: 1em;
  padding-bottom: 1em;
  position: absolute;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  animation-name: expand;
  animation-duration: 0.1s;
  animation-timing-function: ease-out;
  transition: ease-out;
  z-index: 1;

  @keyframes expand {
    0% {
      transform: scale(0.3);
    }

    100% {
      transform: scale(1);
    }
  }

  a {
    color: black;
    cursor: pointer;
    text-decoration: none;
  }
`

const OptionsModalItem = styled.div`
  padding: 1em 1em;
  display: flex;
  align-items: center;
  &:hover {
    background: #f7f7f7;
  }
  cursor: pointer;
`
const OptionsModalText = styled.div`
  padding-left: 2em;
`

const LoadingContainer = styled.div`
  position: absolute;
  left: 0.2em;
  margin-right: 2em;
`

const MeetingOptionsModal = ({
  setIsMeetingOptionsModalOpen,
  setIsShareLinkModalOpen,
  setSelectedModalOption,
  selectedModalOption,
  onStartInstantMeeting,
  createMeetingLoading,
}) => {
  const { t } = useTranslation('newMeetingOptionsModal')

  return (
    <OutsideClickHandler
      onOutsideClick={() => setIsMeetingOptionsModalOpen(false)}
    >
      <OptionsModal>
        <OptionsModalItem
          onClick={() => {
            if (!createMeetingLoading) {
              setIsShareLinkModalOpen(true)
              setIsMeetingOptionsModalOpen(false)
            }
          }}
        >
          <FiLink2 size="1.6em" />
          <OptionsModalText>{t('createMeeting')}</OptionsModalText>
        </OptionsModalItem>
        <OptionsModalItem
          onClick={() => {
            setSelectedModalOption(2)
            onStartInstantMeeting()
          }}
        >
          {createMeetingLoading && selectedModalOption === 2 ? (
            <>
              <LoadingContainer>
                <LottieShape animationData={LoadingAnimation} size={52} />
              </LoadingContainer>
              <div style={{ marginRight: '1.65em' }}></div>
            </>
          ) : (
            <FiPlus size="1.6em" />
          )}
          <OptionsModalText>{t('startMeeting')}</OptionsModalText>
        </OptionsModalItem>
        <OptionsModalItem
          onClick={() => {
            setSelectedModalOption(3)
            onStartInstantMeeting()
          }}
        >
          {createMeetingLoading && selectedModalOption === 3 ? (
            <>
              <LoadingContainer>
                <LottieShape animationData={LoadingAnimation} size={52} />
              </LoadingContainer>
              <div style={{ marginRight: '1.65em' }}></div>
            </>
          ) : (
            <FiSettings size="1.6em" />
          )}
          <OptionsModalText>{t('customMeeting')}</OptionsModalText>
        </OptionsModalItem>
      </OptionsModal>
    </OutsideClickHandler>
  )
}

MeetingOptionsModal.propTypes = {
  setIsMeetingOptionsModalOpen: PropTypes.func.isRequired,
  setIsShareLinkModalOpen: PropTypes.func.isRequired,
  setSelectedModalOption: PropTypes.func.isRequired,
  selectedModalOption: PropTypes.number.isRequired,
  onStartInstantMeeting: PropTypes.func.isRequired,
  createMeetingLoading: PropTypes.bool.isRequired,
}

export default MeetingOptionsModal
